
@use '@angular/material' as mat;
@include mat.core();

$skinvaders-frontend-primary: mat.define-palette(mat.$indigo-palette);
$skinvaders-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$skinvaders-frontend-warn: mat.define-palette(mat.$red-palette);
$skinvaders-frontend-theme: mat.define-light-theme((
  color: (
    primary: $skinvaders-frontend-primary,
    accent: $skinvaders-frontend-accent,
    warn: $skinvaders-frontend-warn,
  )
));

@include mat.all-component-themes($skinvaders-frontend-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MorganiteBold';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Morganite-Bold.woff');
}

@font-face {
    font-family: 'Morganite';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Morganite-Medium.woff');
}

@font-face {
    font-family: 'MontrealBook';
    src: url('assets/fonts/ppneuemontreal-book-webfont.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontrealMedium';
    src: url('assets/fonts/ppneuemontreal-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montreal';
    src: url('assets/fonts/ppneuemontreal-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body {
    height: 100%;
    background-color: #E6E2F0;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;}
.mat-app-background {
    background-color: inherit;
}

// .hero-card:hover {
//    transform: scale(1.1) rotate3d(1, -4, 1, 20deg);
//}
//.hero-card:hover {@apply duration-100 transition-all shadow-lg;}

a {@apply underline hover:no-underline hover:text-gray-700 transition-all duration-150;}

a.primary, a.footer {@apply no-underline inline-block relative;}

a.primary::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #16181A;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
 a.primary:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

a.footer:hover {@apply text-white;}

a.footer::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
 a.footer:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  button.primary {@apply bg-grey-dark text-white p-4 font-montrealBook rounded-md relative text-base sm:text-lg;}

  /*button:before (attr data-hover)*/
  button.primary:hover:before{opacity: 1; transform: translate(0,0);}
  button.primary:before{
    content: attr(data-hover);
    position: absolute;
    top: 1.1em; 
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translate(0,60%);
    transition: all .3s ease-in-out;
  }
    /*button div (button text before hover)*/
    button.primary:hover div{opacity: 0; transform: translate(0,-60%)}
    button.primary div{
      transition: all .3s ease-in-out;
    }
